<template>
    <div>
        <v-container class="my-2">
            <v-layout row wrap px-0 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.items.list_path">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                {{ item.title.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3>
                    <v-btn color="primary" outlined class="float-end" to="/add-item">
                        <v-icon>
                            mdi-plus
                        </v-icon>
                        Add New Item
                    </v-btn>
                </v-flex>
            </v-layout>
           
            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-card flat>
                        <v-card-title primary-title>
                            <v-text-field
                                label="Search"
                                v-model="search"
                                append-icon="mdi-magnify"
                                class="mx-4"
                                outlined
                            ></v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :loading="loading" :headers="headers" :search="search" :items="rows"
                                class="elevation-0" item-key="item_id">
                                <template v-slot:[`item.item_id`]="{ item }">
                                    <div>
                                        <v-btn icon :to="'/items-list/'+item.item_id" color="teal" class="mx-1">
                                            <v-icon> mdi-pencil-outline </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="copyUrl(item)" color="blue" class="mx-1">
                                            <v-icon>
                                                mdi-content-copy
                                            </v-icon>
                                        </v-btn>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="error" icon class="mx-1" v-bind="attrs" v-on="on">
                                                    <v-icon> mdi-delete-outline </v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-text>
                                                    {{$store.getters.language.data.items.delete_question}}
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text class="elevation-0">
                                                        {{$store.getters.language.data.items.cancel_btn}}
                                                    </v-btn>
                                                    <v-btn color="error" class="elevation-0"
                                                        @click="deleteItems(item.item_id)">
                                                        {{$store.getters.language.data.items.yes_btn}}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-menu>
                                    </div>
                                </template>
                                <template v-slot:[`item.item_status`]="{ item }">
                                    <v-chip small :color="item.item_status == 'active' ? 'green' : 'red'"
                                        text-color="white">{{item.item_status}}</v-chip>
                                </template>
                                <template v-slot:[`item.item_url`]="{ item }">
                                    <a :href="item.item_url" target="_blank">
                                        {{item.item_url}}
                                        <v-icon class="mx-1" color="blue">
                                            mdi-open-in-new
                                        </v-icon>
                                    </a>
                                </template>
                            </v-data-table>
                        </v-card-text>

                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>

        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import requests from './../../requests/items.request.js'
    import * as xlsx from 'xlsx/xlsx.js';
    export default {
        data() {
            return {
                items: {},
                search: '',
                loading: true,
                loading_btn: false,

                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows: [],
                sections: [],
                selected_items: {},
                headers: [


                    {
                        text: 'Section',
                        align: 'start',
                        sortable: true,
                        value: 'section_name_en',
                    },

                    {
                        text: 'Title',
                        align: 'start',
                        sortable: true,
                        value: 'item_title_en',
                    },
                    {
                        text: 'Type',
                        align: 'start',
                        sortable: true,
                        value: 'item_type',
                    },
                    {
                        text: 'Url',
                        align: 'start',
                        sortable: true,
                        value: 'item_url',
                    },
                    {
                        text: 'Price',
                        align: 'start',
                        sortable: true,
                        value: 'item_price',
                    },
                    {
                        text: 'Status',
                        align: 'start',
                        sortable: true,
                        value: 'item_status',
                    },
                    {
                        text: this.$store.getters.language.data.items.item_index,
                        align: 'start',
                        sortable: true,
                        value: 'item_index',
                    },
                    {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'item_id',
                    }
                ],
            }
        },
        computed: {
            page() {
                const pageKey = 'ITEMS'
                return {}
            }
        },
        mounted() {
            this.readItems();
        },
        methods: {
            copyUrl(item) {
                const mainDomain = window.location.origin
                const url = mainDomain + '/p/' + item.item_id
                navigator.clipboard.writeText(url).then(() => {
                    this.snackbar = {
                        value: true,
                        text: 'Url Copied',
                        color: 'success'
                    }
                })
            },

            deleteItems(item_id) {
                requests.deleteItems(item_id).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f => {
                            return f.item_id != item_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'Items Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },

            readItems() {
                this.loading = true
                requests.getAllItems().then(r => {
                        if (r.status == 200) {
                            this.rows = r.data.items

                            this.sections = r.data.sections

                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to read Items',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Items',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            selectItems(i) {
                this.selected_items = i
                this.delete_dialog = true
            },
        },
    }
</script>